<template>
  <v-form ref="form">
    <v-toolbar color="pink" dark flat dense cad>
      <v-toolbar-title class="subheading">
        <div class="form-btn">
          Saída {{ this.id }}
          <v-btn v-if="resumo" @click="visualizarNormal" outline>Normal</v-btn>
          <v-btn v-else @click="visualizarResumo" outline>Resumo</v-btn>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Promotor de vendas"
            name="Promotor"
            placeholder="Promotor"
            v-model="notaSaida.nomePromotor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praça"
            placeholder="Praça"
            v-model="notaSaida.praca"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Vendedor(a)"
            name="Vendedor(a)"
            placeholder="Vendedor(a)"
            v-model="notaSaida.nomeVendedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="CPF"
            name="CPF"
            placeholder="CPF"
            v-model="notaSaida.cpf"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Telefone"
            name="Telefone"
            placeholder="Telefone"
            v-model="notaSaida.telefone"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Endereço"
            name="Endereço"
            placeholder="Endereço"
            v-model="notaSaida.endereco"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div v-if="!resumo" class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item.codigoBarras }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
            </template>
          </v-data-table>
        </div>
        <div v-else class="d-flex">
          <v-data-table :headers="headersResumido" :items="produtosResumidos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
              <td>{{ props.item.quantidade }}</td>
              <td>{{ props.item.semComissao == 1 ? "SIM" : "NÃO" }}</td>
            </template>
          </v-data-table>
        </div>
        <div class="form-btn">
          <v-btn @click="voltar" outline>Voltar</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { ServicoSaida } from "../../servicos/servicoSaida"
const servicoSaida = new ServicoSaida()
export default {
  data() {
    return {
      notaSaida: "",
      produtos: [],
      produtosResumidos: [],
      resumo: false,
      headers: [
        { text: "Código de barras", align: "left", sortable: false, value: "codigoBarras" },
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Preço de venda", align: "left", sortable: false, value: "precoVenda" }
      ],
      headersResumido: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Preço de venda", align: "left", sortable: false, value: "precoVenda" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "Sem Comissão", align: "left", sortable: false, value: "semComissao" }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSaida.buscarPorId(this.id).then(res => {
        this.notaSaida = res.data
        this.produtos = res.data.produtos
        this.produtosResumidos = res.data.produtosResumidos
      })
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "saidaConsignada" })
    },
    visualizarResumo() {
      this.resumo = true
    },
    visualizarNormal() {
      this.resumo = false
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
